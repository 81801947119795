<template>
  <div class="abnormal">
		<div class="search-box">
			<el-form :inline="true" :model="formSearch" class="demo-form-inline" label-width="100px">
				<el-form-item label="IP">
					<el-input v-model="formSearch.ip" placeholder="请输入IP"></el-input>
				</el-form-item>
				<el-form-item label="异常时间">
					<el-date-picker v-model="formSearch.time" value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"></el-date-picker>
				</el-form-item>
				<el-form-item style="text-align: right;" class="fr">
					<el-button type="primary" @click="searchNameFn">检索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<!-- 列表 -->
		<div class="table-box">
			<el-card>
				<el-table v-loading="loadingFlag" border :data="tableData" @selection-change="handleSelectionChange" :row-style="{height:'48px'}" empty-text="加载中..." style="width: 100%;">
					<el-table-column type="selection" width="30"></el-table-column>
					<el-table-column type="index" :index="typeIndex" label="序号" width="50"></el-table-column>
					<el-table-column prop="url" label="请求URL" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="method" label="请求方式" width="100"></el-table-column>
					<el-table-column prop="params" label="请求参数" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="ip" label="操作IP" width="200"></el-table-column>
					<el-table-column prop="exception" label="异常原因" :show-overflow-tooltip="true"></el-table-column>
					<el-table-column prop="createTime" label="异常时间" width="200"></el-table-column>
					<template slot="empty">
						<img v-if="dataTableEmpty" class="data-pic" :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;" />
					</template>
				</el-table>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportONE" style="float: left;margin-top:10px;margin-right: 20px;">{{exportOneName}}</el-button>
				<el-button v-show="totalItems != 0" type="primary" size="mini" @click="exportALLFn" style="float: left;margin-top:10px;">{{exportAllName}}</el-button>
				<el-pagination @current-change="handleCurrentChange" :total="totalItems" :current-page="currentPage" :page-size="pageSize" layout="total, prev, pager, next, jumper"></el-pagination>
			</el-card>
		</div>
  </div>
</template>

<script>
import { exportExcel } from '../../provider/index.js'
export default {
  name: 'abnormal',
  data() {
		return {
			baseUrl:this.$pulick.baseUrl,//默认路径目录  加载静态文件img
			//异常时间
			pickerOptions: {
				shortcuts: [{
					text: '最近一周',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近一个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit('pick', [start, end]);
					}
				}, {
					text: '最近三个月',
					onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit('pick', [start, end]);
					}
				}]
			},
			formSearch:{
				ip: '',
				time:null,
			},
			loadingFlag: false,
			dataTableEmpty:false,
			tableData: [], //列表  数组
			currentPage: 1, //当前页
			pageSize: 14, //每页  条数
			totalItems: 0, //列表 总数
			multipleSelection: [], //导出参数   数据前  复选框
			exportOneName:"导出所选",
			exportAllName:"导出全部",
		};
  },
  mounted() {}, 
  created() {
		this.getPageList(); //加载列表
  },
  methods: {
		//点击查询  按钮
		searchNameFn() {
			this.currentPage = 1; //初始第一页
			this.getPageList(); //加载列表
		},
		//加载列表
		getPageList() {
			this.loadingFlag = true;//loading 显示
			this.dataTableEmpty = false;
			this.$provider.get('/wg-system/log/errorLogPage',{
				params:{
					ip: this.formSearch.ip,
					startTime: this.formSearch.time != null ? this.formSearch.time[0] : "",
					endTime: this.formSearch.time != null ? this.formSearch.time[1] : "",
					current: this.currentPage,
					size: this.pageSize,
				}
			}).then(res=>{
				this.loadingFlag = false;//loading 消失
				this.dataTableEmpty = true;
				this.tableData = res.data.records;
				this.totalItems = res.data.total;
			})
		},
		//处理分页数据的 index
		typeIndex(index) {
			return (this.currentPage - 1) * this.pageSize + index + 1;
		},
		//当前页面  属于第几页
		handleCurrentChange(val) {
			this.currentPage = val;
			this.getPageList(); //加载列表
		},
		//表格 每行  复选框选值    导出
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		//导出所选
		exportONE() {
			let chk_value = '';
			this.multipleSelection.forEach(function(f, i) {
				if (i == 0) {
					chk_value = f.id;
				} else {
					chk_value += "," + f.id;
				}
			})
			if (chk_value.length != 0) {
				let myObj = {
					method: 'get',
					url: this.$pulick.urlStr + '/wg-system/log/exportByIds',
					fileName: '异常日志',
					params: 'ids='+chk_value+'&logFlag=false'
				}
				this.exportOneName = "正在导出中...";
				exportExcel(myObj,this);// 导出Excel公用方法
			} else {
				this.$message({
					message: '请先勾选',
					type: 'warning'
				});
				return false;
			}
		},
		//导出全部
		exportALLFn() {
			let startTime = this.formSearch.time != null ? this.formSearch.time[0] : "";
			let endTime = this.formSearch.time != null ? this.formSearch.time[1] : "";
			let myObj = {
				method: 'get',
				url: this.$pulick.urlStr + '/wg-system/log/export',
				fileName: '异常日志',
				params:'logFlag=false&ip='+this.formSearch.ip+'&startTime='+startTime+'&endTime='+endTime,
			} 
			this.exportAllName = "正在导出中...";
			exportExcel(myObj,this);// 导出Excel公用方法
		},
  }
};
</script>

<style scoped="scoped"></style>
